<template>
    <div class="login" style=" ">

        <h1 style="    font-size: 30px;
                        font-weight: 700;
                        text-align: center;
                        color: #fff;
                        margin-top:140px;
                        margin-bottom: 0px;
                        ">Banner WMS</h1>
        <div style="border-radius: 10px;
                    margin: 5px 15px 5px 8px;
                    padding: 0px 20px 10px 0px;
                    width:60%;
                    height:450px;
                    margin:50px auto;
                    background-color: white;">
            <div class="leftFloat" style="width:40%">
                <div style="margin:60px 0px 0px 30px;font-size:25px;font-weight: 700;color:#5379f6">智慧仓库管理系统</div>
                <div style="margin:5px 0px 5px 60px;color:#6c6b06">智能算法业务引擎 仓库大脑</div>
                <img style="width:300px;margin-left:30px;margin-top:0px" src="../assets/images/scan.jpg">
            </div>

            <div class="loginModal rightFloat ">
                <ul>
                    <li>
                        <span style="    font-size: 24px;
                                font-weight: 700;
                                text-align: center;
                                margin-bottom: 10px;
                                color: #55667b;">
                            登录
                        </span>

                    </li>
                    <li>
                        <el-input @keyup.enter.native="commit" placeholder="请输入账号" v-model="form.account"
                            prefix-icon="iconfont icon-ef-zhanghao" clearable>
                        </el-input>
                    </li>
                    <li>
                        <el-input @keyup.enter.native="commit" placeholder="请输入密码" v-model="form.password"
                            prefix-icon="iconfont icon-mima" show-password clearable></el-input>
                        <div class="clearFloat" style="margin-top:10px">
                            <el-slider title="拖到右侧" class="leftFloat" v-model="sliderValue" :show-tooltip="false"
                                :disabled="sliderValue === 100" style="width: 10%; margin-left: 5%"></el-slider>
                            <div class="leftFloat" style="
                                        font-size: 14px;
                                        line-height: 38px;
                                        margin-left: 10px;
                                    ">
                                <span v-show="sliderValue !== 100" style="color: #a9b0c6">滑动解锁</span>
                                <span v-show="sliderValue === 100" style="color: #08274c">已解锁!</span>
                            </div>


                        </div>
                    </li>
                    <li>
                        <el-button @click="commit" type="primary" :disabled="
                            form.account === '' ||
                            form.password === '' ||
                            sliderValue !== 100
                        " style="width: 100%;height:50px;font-size:20px;background-color:#5379f6">登陆</el-button>
                    </li>
                </ul>            
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
    name: "Login",
    data() {
        let validatePassword2 = (rule, value, callback) => {
            if (value != this.newOrg.password) {
                callback(new Error('两次密码不一致'));
            } else {
                callback();
            }
        };
        let validatePassword3 = (rule, value, callback) => {
            if (value != this.updatePassword.password) {
                callback(new Error('两次密码不一致'));
            } else {
                callback();
            }
        };
        return {
            dialogUpdateVisible: false,
            urlOrgId: '11',

            timer: null,
            timeCode: 60,
            timeBool: false,
            formLabelWidth: '80px',
            newOrg: {},
            updatePassword: {
                mobile: '',
                code: '',
                password: '',
                password2: '',
            },
            dialogNewVisible: false,
            form: {
                account: "testUser",
                password: "1234567",
                systemType: 1,
            },
            sliderValue: 100,


        };
    },
    computed: {
        account() {
            return this.form.account;
        },
        password() {
            return this.form.password;
        },
    },
    watch: {
        sliderValue(val) {
            if (val !== 100) {
                this.sliderValue = 0;
            }
        },
        account(val) {
            this.sliderValue = 0;
        },
        password(val) {
            this.sliderValue = 0;
        },
    },
    components: {
        Footer,
    },
    methods: {
        getQueryVariable(variable) {
            var query = window.location.hash.split("?")[1];
            if (query == null || query == '') {
                return;
            }
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) { return pair[1]; }
            }
            return (false);
        },
        getAuthParams() {
            this.urlOrgId = this.getQueryVariable('orgId');
            this.newOrg.urlOrgId = this.urlOrgId
        },
        register() {
            let that = this;

            this.$refs["newOrg"].validate((valid) => {
                if (valid) {
                    IOT.showOverlay('保存中，请稍等...');
                    let newOrg = that.newOrg;
                    let url = '/users/register';
                    IOT.getServerData(url, 'post', that.newOrg, (ret) => {
                        IOT.hideOverlay();
                        if (ret.code === 200) {
                            that.dialogNewVisible = false;
                            IOT.tips('注册成功！', 'success', 1000, () => {
                                that.form.account = newOrg.phoneNumber;
                                that.form.password = newOrg.password;
                                that.sliderValue = 100;
                                that.commit();
                            });
                        } else {
                            IOT.tips(ret.data || ret.message, 'error');
                        }
                    });

                } else {
                    IOT.hideOverlay();
                    console.error('error submit!!');
                    return false;
                }
            });
        },
        updatePasswordCommit() {
            let that = this;
            this.$refs["updatePassword"].validate((valid) => {
                if (valid) {
                    IOT.showOverlay('保存中，请稍等...');
                    let updatePassword = that.updatePassword;
                    let url = '/users/retrieval/password';
                    IOT.getServerData(url, 'post', that.updatePassword, (ret) => {
                        IOT.hideOverlay();
                        if (ret.code === 200) {
                            that.dialogUpdateVisible = false;
                            IOT.tips('注册成功！', 'success', 1000, () => {
                                that.form.account = updatePassword.mobile;
                                that.form.password = updatePassword.password;
                                that.sliderValue = 100;
                                that.commit();
                            });
                        } else {
                            IOT.tips(ret.data || ret.message, 'error');
                        }
                    });

                } else {
                    return false;
                }
            });
        },
        phoneCode() {
            let that = this;
            var phoneNo = that.newOrg.phoneNumber.trim();
            var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!myreg.test(phoneNo)) {
                IOT.tips('请输入正确的11位手机号码', 'error');
                return;
            }

            let url = '/users/phoneMap';

            IOT.getServerData(url, 'get', { phoneNo: phoneNo }, (ret) => {
                if (ret.code === 200) {
                    that.timeBool = true;
                    that.timeCode = 60;
                    that.timer = setInterval(function () {

                        if (that.timeCode == 0) {
                            clearInterval(that.timer);
                            that.timeBool = false;
                            that.timeCode = 60;
                        } else {
                            that.timeCode--;
                        }
                    }, 1000);
                    this.$message({
                        message: '验证码发送到您的手机，请您注意查看',
                        type: 'success'
                    });
                } else {
                    that.timeBool = false;
                    that.timeCode = 60;
                    IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                }
            });
        },

        //找回密码使用
        phoneCode2() {
            let that = this;
            var phoneNo = that.updatePassword.mobile.trim();
            var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!myreg.test(phoneNo)) {
                IOT.tips('请输入正确的11位手机号码', 'error');
                return;
            }

            let url = '/users/phoneMap2';


            IOT.getServerData(url, 'get', { phoneNo: phoneNo }, (ret) => {
                if (ret.code === 200) {
                    this.$message({
                        message: '验证码发送到您的手机，请您注意查看',
                        type: 'success'
                    });
                    that.timeBool = true;
                    that.timeCode = 60;
                    that.timer = setInterval(function () {
                        if (that.timeCode == 0) {
                            clearInterval(that.timer);
                            that.timeBool = false;
                            that.timeCode = 60;
                        } else {
                            that.timeCode--;
                        }
                    }, 1000);
                } else {
                    that.timeBool = false;
                    that.timeCode = 60;
                    IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                }
            });
        },

        getMenuIndex(url, m1, m2, m3) {
            let index = 0;
            for (let i = 0; i < m1.length; i++) {
                const e = m1[i];
                if (e.url === url) {
                    index = 0;
                    break;
                }
            }
            for (let i = 0; i < m2.length; i++) {
                const e = m2[i];
                if (e.url === url) {
                    index = 1;
                    break;
                }
            }
            for (let i = 0; i < m3.length; i++) {
                const e = m3[i];
                if (e.url === url) {
                    index = 2;
                    break;
                }
            }
            return index;
        },
        commit() {
            var that = this;
            if (!this.form.account) {
                PF.publicMessage(
                    '请输入<i class="iconfont icon-zhanghao"></i>账号!',
                    "error"
                );
                return false;
            } else if (!this.form.password) {
                PF.publicMessage(
                    '请输入<i class="iconfont icon-mima"></i>密码!',
                    "error"
                );
                return false;
            } else if (this.sliderValue !== 100) {
                PF.publicMessage("请滑动验证!", "error");
                return false;
            }
            PF.publicLoadingShow("登陆中,请稍后...");

            IOT.getServerData(
                "/login",
                "post",
                this.form,
                (ret) => {
                    PF.publicLoadingHide();
                    if (ret.code === 200) {
                        PF.publicMessage("登陆成功!", "success", 1000);
                        PF.setLocal("account", that.form.account);
                        PF.setLocal("password", that.form.password);
                        PF.setLocal("token", ret.data.token);
                        PF.setLocal("userId", ret.data.userId);
                        PF.setLocal("userName", ret.data.userName);
                        PF.setLocal("iconUrl", ret.data.iconUrl);
                        PF.setLocal("permission", ret.data.permission);
                        PF.setLocal("dingDeptIds", ret.data.dingDeptIds);
                        PF.setLocal("dingUserId", ret.data.dingUserId);
                        PF.setLocal("dingName", ret.data.dingName);
                        PF.setLocal("wxOpenId", ret.data.wxOpenId);
                        // PF.setLocal("organizationId", ret.data.organizationId);
                        // PF.setLocal("organizationName", ret.data.organizationName);
                        PF.setLocal("menus1", ret.data.menus1);
                        PF.setLocal("menus2", ret.data.menus2);
                        PF.setLocal("menus3", ret.data.menus3);
                        // localStorage.setItem(
                        //     "menus2",
                        //     JSON.stringify(ret.data.menus2)
                        // );
                        // localStorage.setItem(
                        //     "menus3",
                        //     JSON.stringify(ret.data.menus3)
                        // );

                        // 根据返回的角色路径匹配到菜单所属的4个选项

                        PF.setLocal("menu_index", 0);
                        this.$router.push({ path: '/homepage' });
                    } else {
                        PF.publicMessage(
                            ret.message || "服务器请求错误!",
                            "error"
                        );
                    }
                },
                true
            );

            /*this.$http.post('/login', this.form).then(res=>{
                    PF.publicLoadingHide();
                    if(res.data.code === 200){
                        PF.publicMessage('登陆成功!','success');
                        localStorage.setItem("token",res.data.data.token);
                        localStorage.setItem("userName",res.data.data.userName);
                        localStorage.setItem("menus1",JSON.stringify(res.data.data.menus1));
                        localStorage.setItem("menus2",JSON.stringify(res.data.data.menus2));
                        localStorage.setItem("menus3",JSON.stringify(res.data.data.menus3));
                        this.$router.push({name: 'home'})
                    }else{
                        PF.publicMessage(res.data.message || '服务器请求错误!','error');
                    }
                }).catch(err=>{
                    console.error(err || '未知的错误!','error');
                });*/
        },
    },
    created() {
        var account = PF.getLocal('account');
        var password = PF.getLocal('password');
        if (
            account != '' && account != null && account != undefined
            && password != '' && password != null && password != undefined

        ) {
            this.form.account = account;
            this.form.password = password;
        }


        this.getAuthParams()
    },
    mounted() { },
    destroyed() {
        clearInterval(this.timer);
    },
};
</script>

<style scoped lang="less">
.login {
    color: #08274c;
    padding-bottom: 30px;
    height: 1200px;
    overflow: hidden;
    background-image: url(../assets/images/20220403010653.jpg);

    .loginModal {
        width: 50%;
        height: 335px;
        margin: 20px 30px 0px 0px;
        background: #fff;

        ul {
            width: 280px;
            margin: 50px auto;

            li {
                text-align: center;
                margin: 20px 0;
            }

            li:first-child {
                span {
                    display: inline-block;
                    width: 50%;
                    line-height: 30px;

                }
            }
        }
    }
}
</style>
